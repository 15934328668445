const lang = {
     'langOrder': 3,
     'langCode': 'en',
     'langName': 'English',

     /**
     * layouts/LoggedLayout.js
     */
     'loggedlayout.top.hello': 'Hello',
     'loggedlayout.top.logout': 'Log out',
     'loggedlayout.menu.home': 'Home',
     'loggedlayout.menu.consumptions': 'Consumptions',
     'loggedlayout.menu.refuelings': 'Refuelings',
     'loggedlayout.menu.invoices': 'Invoices',
     'loggedlayout.menu.account': 'My details',

     /**
     * pages/Login.js
     */
     'login.leftText.title': 'CUSTOMER AREA',
     'login.leftText.welcometo': 'Welcome to',
     'login.leftText.line1': 'View all your invoices and download them to your mobile',
     'login.leftText.line2': 'Take a quick look at your consumption by month or by enrollment',
     'login.leftText.line3': 'Check the price of all our stations',
     'login.langselector.label': 'Select language',
     'login.recoverpasswordthrottle': 'You tried to request a new password too quickly. Please wait 1 minute to try again.',
     'login.invalidlogin': 'Invalid login details',
     'login.recoverpasswordemailsent': 'We have sent you an email with the instructions to recover your password',
     'login.loginForm.input.user': 'NIF/CIF (no spaces)',
     'login.loginForm.input.password': 'PASSWORD',
     'login.loginForm.loginbutton': 'LOGIN',
     'login.loginForm.lostpasswordButton': 'Forgot password',
     'login.recoverPasswordForm.text1' : 'Enter your NIF/CIF to receive an email with the',
     'login.recoverPasswordForm.text2' : 'instructions to recover your password',
     'login.recoverPasswordForm.usernameInput': 'NIF/CIF',
     'login.recoverPasswordForm.button': 'REQUEST NEW PASSWORD',
     'login.recoverPasswordForm.gobackbutton': 'Go back',
     'login.noaccountask': "Don't have a Plenoil account yet?",
     'login.createaccountbutton': 'Create account',
     'login.privacylink': 'Terms of use and Privacy Policy',

     /**
     * pages/Logout.js
     */
     'logout.text': 'Logging out...',

          /**
     * pages/Register.js
     */
     'register.title': 'Welcome',
     'register.subtitle': 'Complete the following fields to register with Plenoil',
     'register.registerErrorMessage': 'Please fix any errors before continuing',
     'register.genericErrorMessage': 'An error occurred, please try again',
     'register.confirmationMessageResent' : 'Confirmation message resent',
     'register.title.userData': 'User data',
     'register.inputs.name.label': 'Name and surname / Company name',
     'register.inputs.name.placeholder': 'S.L. Company Name',
     'register.inputs.vatnumber.label': 'CIF/NIF',
     'register.inputs.vatnumber.placeholder': 'XXXXXXXXX',
     'register.inputs.email.label': 'Email',
     'register.inputs.email.placeholder': 'email@example.com',
     'register.inputs.repeatemail.label': 'Repeat email',
     'register.inputs.repeatemail.placeholder': 'email@example.com',
     'register.inputs.password.label': 'Password (minimum 6 digits)',
     'register.inputs.password.placeholder': '******',
     'register.inputs.phone.label': 'Phone',
     'register.inputs.phone.placeholder': 'XXXXXXXXX',
     'register.inputs.country.label': 'Country',
     'register.inputs.country.placeholder': 'Your country',
     'register.inputs.province.label': 'Province',
     'register.inputs.province.placeholder': 'Your province',
     'register.inputs.city.label': 'City',
     'register.inputs.city.placeholder': 'Your location',
     'register.inputs.address.label': 'Address',
     'register.inputs.address.placeholder': 'C/ Example, 10, 1A',
     'register.inputs.postalcode.label': 'Postal Code',
     'register.inputs.postalcode.placeholder': 'XXXXX',
     'register.title.invoices': 'INVOICES',
     'register.invoices.generate': 'Generate invoices',
     'register.inputs.invoices.porsuministros.label': 'PER SUPPLY',
     'register.inputs.invoices.mensuales.label': 'MONTHLY',
     'register.inputs.invoices.porsuministros.text': 'you will receive an invoice for each refueling',
     'register.inputs.invoices.mensuales.text': 'you will receive a monthly invoice with all refueling',
     'register.title.matriculas': 'Matriculas',
     'register.inputs.matriculas.input.placeholder': 'Enter registration',
     'register.inputs.matriculas.addVehicleButton': 'Add vehicle',
     'register.policy.text1': 'I have read and accept the',
     'register.policy.text2': 'Privacy Policy',
     'register.createAccountButton': 'CREATE ACCOUNT',
     'register.goBackButton': 'Go Back',
     'register.registrationCompleted.title': 'Account created',
     'register.registrationCompleted.text': 'Shortly you will receive an email to verify your account',
     'register.registrationCompleted.verifiedButton': 'Already verified',
     'register.registrationCompleted.resendEmailButton': 'Resend Email',

     /**
     * pages/Account/index.js
     */
     'account.goBackButton': 'Back',
     'account.userEdit.successMessage': 'Data received',
     'account.userEdit.errorMessage': 'Error receiving data',
     'account.userEdit.saveButton': 'Save',
     'account.userEdit.concentrador.onprogressmessage': 'Update in progress. It will be available shortly. You can continue browsing.',

     'account.userEdit.title': 'USER DATA',
     'account.userEdit.input.name.label': 'Name and surname / Company name',
     'account.userEdit.input.name.placeholder': 'Company Name SL',
     'account.userEdit.input.phone.label': 'Phone',
     'account.userEdit.input.phone.placeholder': 'XXXXXXXXX',
     'account.userEdit.input.email.label': 'Email',
     'account.userEdit.input.email.placeholder': 'email@example.com',
     'account.userEdit.input.address.label': 'Address',
     'account.userEdit.input.address.placeholder': 'C/ Example, 10, 1A',
     'account.userEdit.input.province.label': 'Province',
     'account.userEdit.input.province.placeholder': 'Your province',
     'account.userEdit.input.city.label': 'City',
     'account.userEdit.input.city.placeholder': 'Your location',
     'account.userEdit.input.postalcode.label': 'Postal Code',
     'account.userEdit.input.postalcode.placeholder': 'XXXXXX',
     'account.userEdit.input.password.label': 'Password',
     'account.userEdit.input.password.placeholder': '******',

     'account.invoicingEdit.title': 'INVOICING',
     'account.invoicingEdit.subtitle': 'Generate invoices',
     'account.invoicingEdit.porsuministro.label': 'PER SUPPLY',
     'account.invoicingEdit.porsuministro.text': 'you will receive an invoice for each refueling.',
     'account.invoicingEdit.mensuales.label': 'MONTHLY',
     'account.invoicingEdit.mensuales.text': 'You will receive a monthly invoice with all refuelings.',
     'account.invoicingEdit.vehicles.title': 'Vehicles',
     'account.invoicingEdit.vehicles.placeholder': 'Enter license plate',
     'account.invoicingEdit.vehicles.addButton': 'Add vehicle',

     /**
     * pages/Consumptions/index.js
     */
     'consumptions.title': 'CONSUMPTIONS',
     'consumptions.barsReportFilters.title': 'Annual consumption summary',
     'consumptions.barsReportFilters.matriculas.all': 'ALL',
     'consumptions.mensualReport.title': 'Monthly consumption summary',
     'consumptions.mensualReport.total': 'Total monthly consumption',
     'consumptions.mensualReport.interestdata': 'Data of interest',
     'consumptions.mensualReport.lastRefueling': 'Last refueling',
     'consumptions.mensualReport.daysBetween': 'Days between refueling',
     'consumptions.mensualReport.oils': 'Fuels',
     'consumptions.mensualReport.totalDiesel': 'Total diesel',
     'consumptions.mensualReport.totalSp95': 'Total SP 95',
     'consumptions.byVehicle.title': 'Consumption by vehicles',


     /**
     * pages/Consumptions/BarsReport.js
     */
     'consumptions.barsReport.empty': 'There are no consumptions',
     'consumptions.barsReport.totalYear': 'TOTAL ANNUAL CONSUMPTION',

     /**
     * pages/Consumptions/PieReport.js
     */
     'consumptions.pieReport.empty': 'There are no consumptions',

     /**
     * pages/Dashboard/index.js
     */
     'dashboard.link.consumptions': 'CONSUMPTIONS',
     'dashboard.link.refuelings': 'REFUELINGS',
     'dashboard.link.invoices': 'INVOICES',
     'dashboard.link.ticketstoinvoices': 'REQUEST INVOICE',
     'dashboard.link.account': 'MY DATA',

     /**
     * pages/Dashboard/Station.js
     */
     'dashboard.station.station': 'GAS STATION',
     'dashboard.station.favorite': 'FAVORITE',
     'dashboard.station.edit': 'Edit',
     'dashboard.station.select': 'Choose station',
     'dashboard.station.diesel': 'DIESEL',
     'dashboard.station.sp95': 'SP95',
     'dashboard.station.todayprice': "Today's price",
     'dashboard.station.nofavorite': "You don't have a favorite station yet",

     /**
     * pages/Dashboard/Statistics.js
     */
     'dashboard.statistics.welcometo': 'Welcome to',
     'dashboard.statistics.lastRefueling': 'LAST REFUELING',
     'dashboard.statistics.summary': 'SUMMARY',
     'dashboard.statistics.summaryof': 'SUMMARY OF',

     /**
     * pages/Invoices/index.js
     */
     'invoices.title': 'INVOICES',
     'invoices.subtitle': 'Summary of invoices for',
     'invoices.table.date': 'DATE',
     'invoices.table.number': 'NUMBER',
     'invoices.table.amount': 'AMOUNT',
     'invoices.table.download': 'DOWNLOAD',
     'invoices.table.empty': 'There are no invoices',
     'invoices.selectAllButton': 'Select All',
     'invoices.unselectButton': 'Unselect',
     'invoices.downloadButton': 'Download invoices',
     'invoices.requestInvoice': 'REQUEST INVOICE',

     /**
     * pages/Refuelings/index.js
     */
     'refuelings.title': 'Refuelings',
     'refuelings.subtitle': 'Monthly consumption summary',
     'refuelings.table.date': 'DATE',
     'refuelings.table.station': 'STATION',
     'refuelings.table.oil': 'FUEL',
     'refuelings.table.liters': 'LITERS',
     'refuelings.table.price': 'PRICE',
     'refuelings.table.amount': 'AMOUNT',
     'refuelings.table.preview': 'PREVIEW',
     'refuelings.table.empty': 'No refuelings',

     /**
     * pages/Refuelings/TicketPreview.js
     */
     'refuelings.ticketpreview.preview': 'TICKET PREVIEW',
     'refuelings.ticketpreview.downloadButton': 'Download ticket',
}

export default lang;
