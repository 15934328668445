const lang = {
     'langOrder': 4,
     'langCode': 'pt',
     'langName': 'Português',

     /**
     * layouts/LoggedLayout.js
     */
     'loggedlayout.top.hello': 'Olá',
     'loggedlayout.top.logout': 'Sair',
     'loggedlayout.menu.home': 'Casa',
     'loggedlayout.menu.consumptions': 'Consumos',
     'loggedlayout.menu.refuelings': 'Reabastecimento',
     'loggedlayout.menu.invoices': 'Faturas',
     'loggedlayout.menu.account': 'Meus detalhes',

     /**
     * pages/Login.js
     */
     'login.leftText.title': 'ÁREA CLIENTE',
     'login.leftText.welcometo': 'Bem-vindo a',
     'login.leftText.line1': 'Veja todas as suas faturas e baixe-as para o seu celular',
     'login.leftText.line2': 'Veja rapidamente seu consumo por mês ou por matrícula',
     'login.leftText.line3': 'Consulte o preço de todas as nossas estações',
     'login.langselector.label': 'Selecionar idioma',
     'login.recoverpasswordthrottle': 'Você tentou solicitar uma nova senha muito rapidamente. Aguarde 1 minuto para tentar novamente.',
     'login.invalidlogin': 'Detalhes de login inválidos',
     'login.recoverpasswordemailsent': 'Enviamos um e-mail com as instruções para recuperar sua senha',
     'login.loginForm.input.user': 'NIF/CIF (sem espaços)',
     'login.loginForm.input.password': 'SENHA',
     'login.loginForm.loginbutton': 'LOGIN',
     'login.loginForm.lostpasswordButton': 'Esqueci a senha',
     'login.recoverPasswordForm.text1' : 'Introduza o seu NIF/CIF para receber um email com o',
     'login.recoverPasswordForm.text2' : 'instruções para recuperar sua senha',
     'login.recoverPasswordForm.usernameInput': 'NIF/CIF',
     'login.recoverPasswordForm.button': 'SOLICITAR NOVA SENHA',
     'login.recoverPasswordForm.gobackbutton': 'Voltar',
     'login.noaccountask': 'Ainda não tem uma conta Plenoil?',
     'login.createaccountbutton': 'Criar conta',
     'login.privacylink': 'Termos de uso e política de privacidade',

          /**
     * pages/Logout.js
     */
     'logout.text': 'Logout...',

          /**
     * pages/Register.js
     */
     'register.title': 'Bem-vindo',
     'register.subtitle': 'Preencha os seguintes campos para se registrar no Plenoil',
     'register.registerErrorMessage': 'Por favor, corrija quaisquer erros antes de continuar',
     'register.genericErrorMessage': 'Ocorreu um erro, tente novamente',
     'register.confirmationMessageResent': 'Mensagem de confirmação reenviada',
     'register.title.userData': 'Dados do usuário',
     'register.inputs.name.label': 'Nome e sobrenome / Nome da empresa',
     'register.inputs.name.placeholder': 'S.L. Nome da empresa',
     'register.inputs.vatnumber.label': 'CIF/NIF',
     'register.inputs.vatnumber.placeholder': 'XXXXXXXXX',
     'register.inputs.email.label': 'E-mail',
     'register.inputs.email.placeholder': 'email@example.com',
     'register.inputs.repeatemail.label': 'Repetir e-mail',
     'register.inputs.repeatemail.placeholder': 'email@example.com',
     'register.inputs.password.label': 'Senha (mínimo 6 dígitos)',
     'register.inputs.password.placeholder': '******',
     'register.inputs.phone.label': 'Telefone',
     'register.inputs.phone.placeholder': 'XXXXXXXXX',
     'register.inputs.country.label': 'Pais',
     'register.inputs.country.placeholder': 'Seu pais',
     'register.inputs.province.label': 'Província',
     'register.inputs.province.placeholder': 'Sua província',
     'register.inputs.city.label': 'Cidade',
     'register.inputs.city.placeholder': 'Sua localização',
     'register.inputs.address.label': 'Endereço',
     'register.inputs.address.placeholder': 'C/ Exemplo, 10, 1A',
     'register.inputs.postalcode.label': 'Código Postal',
     'register.inputs.postalcode.placeholder': 'XXXXX',
     'register.title.invoices': 'FATURAS',
     'register.invoices.generate': 'Gerar faturas',
     'register.inputs.invoices.porsuministros.label': 'POR FORNECIMENTO',
     'register.inputs.invoices.mensuales.label': 'MONTHLY',
     'register.inputs.invoices.porsuministros.text': 'você receberá uma fatura para cada reabastecimento',
     'register.inputs.invoices.mensuales.text': 'você receberá uma fatura mensal com todos os abastecimentos',
     'register.title.matrículas': 'Matrículas',
     'register.inputs.matriculas.input.placeholder': 'Digite o registro',
     'register.inputs.matriculas.addVehicleButton': 'Adicionar veículo',
     'register.policy.text1': 'Li e aceito o',
     'register.policy.text2': 'Política de Privacidade',
     'register.createAccountButton': 'CRIAR CONTA',
     'register.goBackButton': 'Voltar',
     'register.registrationCompleted.title': 'Conta criada',
     'register.registrationCompleted.text': 'Em breve você receberá um e-mail para verificar sua conta',
     'register.registrationCompleted.verifiedButton': 'Já verificado',
     'register.registrationCompleted.resendEmailButton': 'Reenviar e-mail',

     /**
     * pages/Account/index.js
     */
     'account.goBackButton': 'Voltar',
     'account.userEdit.successMessage': 'Dados recebidos',
     'account.userEdit.errorMessage': 'Erro ao receber dados',
     'account.userEdit.saveButton': 'Salvar',
     'account.userEdit.hub.onprogressmessage': 'Atualização em andamento. Em breve estará disponível. Você pode continuar navegando.',

     'account.userEdit.title': 'DADOS DO USUÁRIO',
     'account.userEdit.input.name.label': 'Nome e sobrenome / Nome da empresa',
     'account.userEdit.input.name.placeholder': 'Nome da empresa SL',
     'account.userEdit.input.phone.label': 'Telefone',
     'account.userEdit.input.phone.placeholder': 'XXXXXXXXX',
     'account.userEdit.input.email.label': 'E-mail',
     'account.userEdit.input.email.placeholder': 'email@example.com',
     'account.userEdit.input.address.label': 'Endereço',
     'account.userEdit.input.address.placeholder': 'C/ Exemplo, 10, 1A',
     'account.userEdit.input.province.label': 'Província',
     'account.userEdit.input.province.placeholder': 'Sua província',
     'account.userEdit.input.city.label': 'Cidade',
     'account.userEdit.input.city.placeholder': 'Sua localização',
     'account.userEdit.input.postalcode.label': 'Código Postal',
     'account.userEdit.input.postalcode.placeholder': 'XXXXXX',
     'account.userEdit.input.password.label': 'Senha',
     'account.userEdit.input.password.placeholder': '******',

     'account.invoicingEdit.title': 'INVOICING',
     'account.invoicingEdit.subtitle': 'Gerar faturas',
     'account.invoicingEdit.porsuministro.label': 'POR FORNECIMENTO',
     'account.invoicingEdit.porsuministro.text': 'você receberá uma fatura para cada reabastecimento.',
     'account.invoicingEdit.mensuales.label': 'MONTHLY',
     'account.invoicingEdit.mensuales.text': 'Você receberá uma fatura mensal com todos os abastecimentos.',
     'account.invoicingEdit.vehicles.title': 'Veículos',
     'account.invoicingEdit.vehicles.placeholder': 'Digite a placa',
     'account.invoicingEdit.vehicles.addButton': 'Adicionar veículo',

     /**
     * pages/Consumptions/index.js
     */
     'consumptions.title': 'CONSUMPTIONS',
     'consumptions.barsReportFilters.title': 'Resumo do consumo anual',
     'consumptions.barsReportFilters.matriculas.all': 'ALL',
     'consumptions.mensualReport.title': 'Resumo do consumo mensal',
     'consumptions.mensualReport.total': 'Consumo total mensal',
     'consumptions.mensualReport.interestdata': 'Dados de interesse',
     'consumptions.mensualReport.lastRefueling': 'Último reabastecimento',
     'consumptions.mensualReport.daysBetween': 'Dias entre reabastecimento',
     'consumptions.mensualReport.oils': 'Combustíveis',
     'consumptions.mensualReport.totalDiesel': 'Total diesel',
     'consumptions.mensualReport.totalSp95': 'Total SP 95',
     'consumptions.byVehicle.title': 'Consumo por veículos',


     /**
     * pages/Consumptions/BarsReport.js
     */
     'consumptions.barsReport.empty': 'Não há consumos',
     'consumptions.barsReport.totalYear': 'TOTAL DO CONSUMO ANUAL',

     /**
     * pages/Consumptions/PieReport.js
     */
     'consumptions.pieReport.empty': 'Não há consumos',

     /**
     * pages/Dashboard/index.js
     */
     'dashboard.link.consumptions': 'CONSUMOS',
     'dashboard.link.refuelings': 'REABASTECIMENTOS',
     'dashboard.link.invoices': 'FATURAS',
     'dashboard.link.ticketstoinvoices': 'SOLICITAR FATURA',
     'dashboard.link.account': 'MEUS DADOS',

     /**
     * pages/Dashboard/Station.js
     */
     'dashboard.station.station': 'POSTO DE GASOLINA',
     'dashboard.station.favorite': 'FAVORITO',
     'dashboard.station.edit': 'Editar',
     'dashboard.station.select': 'Escolher estação',
     'dashboard.station.diesel': 'DIESEL',
     'dashboard.station.sp95': 'SP95',
     'dashboard.station.todayprice': 'Preço de hoje',
     'dashboard.station.nofavorite': 'Você ainda não tem uma estação favorita',

     /**
     * pages/Dashboard/Statistics.js
     */
     'dashboard.statistics.welcometo': 'Bem-vindo a',
     'dashboard.statistics.lastRefueling': 'ÚLTIMO REABASTECIMENTO',
     'dashboard.statistics.summary': 'SUMMARY',
     'dashboard.statistics.summaryof': 'RESUMO DE',

     /**
     * pages/Invoices/index.js
     */
     'invoices.title': 'FATURAS',
     'invoices.subtitle': 'Resumo das faturas de',
     'invoices.table.date': 'DATA',
     'invoices.table.number': 'NÚMERO',
     'invoices.table.amount': 'VALOR',
     'invoices.table.download': 'DOWNLOAD',
     'invoices.table.empty': 'Não há faturas',
     'invoices.selectAllButton': 'Selecionar tudo',
     'invoices.unselectButton': 'Desmarcar',
     'invoices.downloadButton': 'Baixar faturas',
     'invoices.requestInvoice': 'SOLICITAR FATURA',

     /**
     * pages/Refuelings/index.js
     */
     'refuelings.title': 'Refuellings',
     'refuelings.subtitle': 'Resumo do consumo mensal',
     'refuelings.table.date': 'DATE',
     'refuelings.table.station': 'ESTAÇÃO',
     'refuelings.table.oil': 'COMBUSTÍVEL',
     'refuelings.table.liters': 'LITROS',
     'refuelings.table.price': 'PREÇO',
     'refuelings.table.amount': 'VALOR',
     'refuelings.table.preview': 'PREVIEW',
     'refuelings.table.empty': 'Sem reabastecimento',

     /**
     * pages/Refuelings/TicketPreview.js
     */
     'refuelings.ticketpreview.preview': 'VISUALIZAÇÃO DO BILHETE',
     'refuelings.ticketpreview.downloadButton': 'Baixar ticket',
}

export default lang;