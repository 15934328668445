import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { assets, downloadBlob } from 'helpers/generic';
import { default as RepostajeService } from 'services/Repostaje';
import moment from 'moment';
import { __ } from 'helpers/i18n';

const TicketStyled = styled.div` 
	margin: 0 auto;

	#preview-wrapper {
		box-shadow: var(--plenoil-shadow);
		padding-top: 10px;
		padding-bottom: 20px;

		img {
			width: 100%;
		}
	}

	#btn-download-ticket {
		font-size: 16px;
		margin-top: -30px;
	}

	#ticket-empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: var(--plenoil-gray2);
		font-weight: 700;
		font-size: 18px;
		width: 100%;
		text-transform: uppercase;

		img {
			display: block;
			width: 120px;
			margin-bottom: 30px;
		}
	}
`;

export default function TicketPreview(props) {
	const [preview, setPreview] = useState(null);

	useEffect(() => {
		let getPreview = async () => {
			let preview = await RepostajeService.getPreview(props.id);
			setPreview(preview);
		}
		getPreview();
	}, [props.id])

	const downloadTicket = async () => {
		let result = await RepostajeService.downloadTicket(props.id);
		// if ( result ) downloadBlob(result, 'plenoil_ticket_' + data.numero + '.pdf');
		if ( result ) downloadBlob(result, 'plenoil_ticket.pdf');
	}

	

	// Render
	return (
		<TicketStyled id="ticket">
			{ preview &&
				<div id="preview-wrapper">
					<img src={preview} />

					<div className="text-center">
						<button className="btn-plenoil btn-plenoil-blue2" id="btn-download-ticket" onClick={() => downloadTicket()}>{__('refuelings.ticketpreview.downloadButton')}</button>
					</div>
				</div>
			}

			{!preview &&
				<div id="ticket-empty">
					<img src={assets('assets/img/icono-vista-ticket.svg')} alt="Vista previa del ticket" />
					{__('refuelings.ticketpreview.preview')}
				</div>
			}
		</TicketStyled>
	);
}