const lang = {
     'langOrder': 2,
     'langCode': 'ca',
     'langName': 'Català',

     /**
     * layouts/LoggedLayout.js
     */
     'loggedlayout.top.hello': 'Hola',
     'loggedlayout.top.logout': 'Tancar sessió',
     'loggedlayout.menu.home': 'Inici',
     'loggedlayout.menu.consumptions': 'Consums',
     'loggedlayout.menu.refuelings': 'Repostatges',
     'loggedlayout.menu.invoices': 'Factures',
     'loggedlayout.menu.account': 'Les meves dades',

     /**
     * pages/Login.js
     */
     'login.leftText.title': 'ÀREA DE CLIENTS',
     'login.leftText.welcometo': 'Benvingut a',
     'login.leftText.line1': 'Visualitza totes les factures i descarrega-les al teu mòbil',
     'login.leftText.line2': 'Fes una ullada ràpida als teus consums per mesos o per matrícules',
     'login.leftText.line3': 'Consulta el preu de totes les nostres estacions',
     'login.langselector.label': 'Seleccionar idioma',
     'login.recoverpasswordthrottle': 'Has intentat sol·licitar una nova contrasenya massa ràpid. Espera 1 minut per tornar-ho a provar.',
     'login.invalidlogin': "Dades d'accés invàlides",
     'login.recoverpasswordemailsent': "T'hem enviat un email amb les instruccions per recuperar la teva contrasenya",
     'login.loginForm.input.user': 'NIF/CIF (sense espais)',
     'login.loginForm.input.password': 'CONTRASENYA',
     'login.loginForm.loginbutton': 'INICIAR SESSIÓ',
     'login.loginForm.lostpasswordButton': 'He oblidat la contrasenya',
     'login.recoverPasswordForm.text1' : 'Introdueix el teu NIF/CIF per rebre un email amb les',
     'login.recoverPasswordForm.text2' : 'instruccions per recuperar la contrasenya',
     'login.recoverPasswordForm.usernameInput': 'NIF/CIF',
     'login.recoverPasswordForm.button': 'SOL·LICITAR NOVA CONTRASENYA',
     'login.recoverPasswordForm.gobackbutton': 'Tornar enrere',
     'login.noaccountask': 'Encara no tens un compte a Plenoil?',
     'login.createaccountbutton': 'Crear compte',
     'login.privacylink': "Termes d'ús i Política de privadesa",

          /**
     * pages/Logout.js
     */
     'logout.text': 'Tancant sessió...',

          /**
     * pages/Register.js
     */
     'register.title': 'Benvingut',
     'register.subtitle': 'Completa els camps següents per registrar-te a Plenoil',
     'register.registerErrorMessage': 'Si us plau, corregiu els errors abans de continuar',
     'register.genericErrorMessage': 'Ha passat un error, si us plau torneu-ho a provar',
     'register.confirmationMessageResent' : 'Missatge de confirmació torna a enviar',
     'register.title.userData': 'Dades d\'usuari',
     'register.inputs.name.label': 'Nom i cognoms / Nom empresa',
     'register.inputs.name.placeholder': 'Nom Empresa SL',
     'register.inputs.vatnumber.label': 'CIF/NIF',
     'register.inputs.vatnumber.placeholder': 'XXXXXXXXX',
     'register.inputs.email.label': 'Email',
     'register.inputs.email.placeholder': 'email@exemple.com',
     'register.inputs.repeatemail.label': 'Repetir email',
     'register.inputs.repeatemail.placeholder': 'email@exemple.com',
     'register.inputs.password.label': 'Contrasenya (mínim 6 digitos)',
     'register.inputs.password.placeholder': '******',
     'register.inputs.phone.label': 'Telèfon',
     'register.inputs.phone.placeholder': 'XXXXXXXXX',
     'register.inputs.country.label': 'País',
     'register.inputs.country.placeholder': 'El teu país',
     'register.inputs.province.label': 'Província',
     'register.inputs.province.placeholder': 'La teva província',
     'register.inputs.city.label': 'Localitat',
     'register.inputs.city.placeholder': 'La teva localitat',
     'register.inputs.address.label': 'Direcció',
     'register.inputs.address.placeholder': 'C/ Exemple, 10, 1A',
     'register.inputs.postalcode.label': 'Codi postal',
     'register.inputs.postalcode.placeholder': 'XXXXX',
     'register.title.invoices': 'FACTURES',
     'register.invoices.generate': 'Generar factures',
     'register.inputs.invoices.porsuministros.label': 'PER SUBMINISTRAMENT',
     'register.inputs.invoices.mensuals.label': 'MENSUALS',
     'register.inputs.invoices.porsuministros.text': 'rebreu una factura per a cada proveïment',
     'register.inputs.invoices.mensuals.text': 'rebreu una factura mensual amb tots els proveïments',
     'register.title.matriculas': 'Matrícules',
     'register.inputs.matriculas.input.placeholder': 'Introduïu matrícula',
     'register.inputs.matriculas.addVehicleButton': 'Afegir vehicle',
     'register.policy.text1': 'He llegit i accepto la',
     'register.policy.text2': 'Política de privadesa',
     'register.createAccountButton': 'CREAR COMPTE',
     'register.goBackButton': 'Tornar',
     'register.registrationCompleted.title': 'Compte creat',
     'register.registrationCompleted.text': 'Aviat rebràs un correu electrònic per verificar el teu compte',
     'register.registrationCompleted.verifiedButton': 'Ja he verificat',
     'register.registrationCompleted.resendEmailButton': 'Reenviar correu',

     /**
     * pages/Account/index.js
     */
     'account.goBackButton': 'Tornar',
     'account.userEdit.successMessage': 'Dades rebudes',
     'account.userEdit.errorMessage': 'Error en rebre les dades',
     'account.userEdit.saveButton': 'Guardar',
     'account.userEdit.concentrador.onprogressmessage': 'Actualització en progrés. En breu estarà disponible. Podeu continuar navegant.',

     'account.userEdit.title': "DADES D'USUARI",
     'account.userEdit.input.name.label': 'Nom i cognoms / Nom empresa',
     'account.userEdit.input.name.placeholder': 'Nom Empresa SL',
     'account.userEdit.input.phone.label': 'Telèfon',
     'account.userEdit.input.phone.placeholder': 'XXXXXXXXX',
     'account.userEdit.input.email.label': 'Email',
     'account.userEdit.input.email.placeholder': 'email@exemple.com',
     'account.userEdit.input.address.label': 'Direcció',
     'account.userEdit.input.address.placeholder': 'C/ Exemple, 10, 1A',
     'account.userEdit.input.province.label': 'Província',
     'account.userEdit.input.province.placeholder': 'La teva província',
     'account.userEdit.input.city.label': 'Ciutat',
     'account.userEdit.input.city.placeholder': 'La teva localitat',
     'account.userEdit.input.postalcode.label': 'Codi postal',
     'account.userEdit.input.postalcode.placeholder': 'XXXXXX',
     'account.userEdit.input.password.label': 'Contrasenya',
     'account.userEdit.input.password.placeholder': '******',

     'account.invoicingEdit.title': 'FACTURACIÓ',
     'account.invoicingEdit.subtitle': 'Generar factures',
     'account.invoicingEdit.porsuministro.label': 'PER SUBMINISTRAMENT',
     'account.invoicingEdit.porsuministro.text': 'rebreu una factura per a cada proveïment.',
     'account.invoicingEdit.mensuales.label': 'MENSUALS',
     'account.invoicingEdit.mensuales.text': 'rebreu una factura mensual amb tots els proveïments.',
     'account.invoicingEdit.vehicles.title': 'Vehicles',
     'account.invoicingEdit.vehicles.placeholder': 'Introduïu matrícula',
     'account.invoicingEdit.vehicles.addButton': 'Afegir vehicle',

     /**
     * pages/Consumptions/index.js
     */
     'consumptions.title': 'CONSUMS',
     'consumptions.barsReportFilters.title': 'Resum anual de consum',
     'consumptions.barsReportFilters.matriculas.all': 'TOTES',
     'consumptions.mensualReport.title': 'Resum mensual de consum',
     'consumptions.mensualReport.total': 'Consum total mensual',
     'consumptions.mensualReport.interestdata': "Dades d'interès",
     'consumptions.mensualReport.lastRefueling': 'Darrer proveïment',
     'consumptions.mensualReport.daysBetween': 'Dies entre proveïments',
     'consumptions.mensualReport.oils': 'Combustibles',
     'consumptions.mensualReport.totalDiesel': 'Total dièsel',
     'consumptions.mensualReport.totalSp95': 'Total SP 95',
     'consumptions.byVehicle.title': 'Consum per vehicles',


     /**
     * pages/Consumptions/BarsReport.js
     */
     'consumptions.barsReport.empty': 'No hi ha consums',
     'consumptions.barsReport.totalYear': 'CONSUM TOTAL ANUAL',

     /**
     * pages/Consumptions/PieReport.js
     */
     'consumptions.pieReport.empty': 'No hi ha consums',


     /**
     * pages/Dashboard/index.js
     */
     'dashboard.link.consumptions': 'CONSUMS',
     'dashboard.link.refuelings': 'REPOSTATGES',
     'dashboard.link.invoices': 'FACTURES',
     'dashboard.link.ticketstoinvoices': 'SOL·LICITAR FACTURA',
     'dashboard.link.account': 'LES MEVES DADES',

     /**
     * pages/Dashboard/Station.js
     */
     'dashboard.station.station': 'GASOLINERA',
     'dashboard.station.favorite': 'FAVORITA',
     'dashboard.station.edit': 'Editar',
     'dashboard.station.select': 'Trieu estació',
     'dashboard.station.diesel': 'DIESEL',
     'dashboard.station.sp95': 'SP95',
     'dashboard.station.todayprice': "Preu d'avui",
     'dashboard.station.nofavorite': 'Encara no tens estació preferida',

     /**
     * pages/Dashboard/Statistics.js
     */
     'dashboard.statistics.welcometo': 'Benvingut a',
     'dashboard.statistics.lastRefueling': 'ÚLTIM REPOSTATGE',
     'dashboard.statistics.summary': 'RESUM',
     'dashboard.statistics.summaryof': 'RESUM DE',

     /**
     * pages/Invoices/index.js
     */
     'invoices.title': 'FACTURES',
     'invoices.subtitle': 'Resum de factures de',
     'invoices.table.date': 'DATA',
     'invoices.table.number': 'NOMBRE',
     'invoices.table.amount': 'IMPORT',
     'invoices.table.download': 'DESCARGAR',
     'invoices.table.empty': 'No hi ha factures',
     'invoices.selectAllButton': 'Seleccioneu-ho tot',
     'invoices.unselectButton': 'Deselecciona',
     'invoices.downloadButton': 'Descarregar factures',
     'invoices.requestInvoice': 'SOL·LICITAR FACTURA',

     /**
     * pages/Refuelings/index.js
     */
     'refuelings.title': 'Repostatges',
     'refuelings.subtitle': 'Resum mensual de consum',
     'refuelings.table.date': 'DATA',
     'refuelings.table.station': 'ESTACIÓ',
     'refuelings.table.oil': 'COMBUSTIBLE',
     'refuelings.table.liters': 'LITROS',
     'refuelings.table.price': 'PREU',
     'refuelings.table.amount': 'IMPORT',
     'refuelings.table.preview': 'VISTA PRÈVIA',
     'refuelings.table.empty': 'No hi ha proveïments',

     /**
     * pages/Refuelings/TicketPreview.js
     */
     'refuelings.ticketpreview.preview': 'VISTA PRÈVIA DEL TICKET',
     'refuelings.ticketpreview.downloadButton': 'Descarregar tiquet',
}

export default lang;